import React from "react"
import { graphql } from "gatsby"
import Layout from '../containers/layout/layout'
import Banner from '../containers/blog/banner'
import BlogA from '../containers/blog/blog-area'
import Seo from '../components/seo'

const Blog = ({ data }) => {
    const url = data.site.siteMetadata.siteUrl;
    const description = `Découvrez nos articles de blog pour vous aider dans votre projet de création de piscine et de jardin.`;

    return(
        <Layout url={url}>
            <Seo title="Notre blog" description={description}/>
            <Banner/>
            <BlogA/>
        </Layout>
    )
}

export default Blog;

export const query = graphql`
query PageBlogQuery {
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
